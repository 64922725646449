
import { updateAppConfig } from '#app'
import { defuFn } from '/var/lib/jenkins/jobs/FRONT/FRONT-INDIE-STUDIO-V3/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "87b27792-9cff-4231-b40b-6de3d4344537"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /* #__PURE__ */ defuFn(inlineConfig)
